import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/app/app.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/assets/styles/pages/app-design-page.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/assets/styles/utils/chrome-bug.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/assets/styles/main.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/assets/styles/components/table.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"_fonts/mori/PPMori-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"_fonts/mori/PPMori-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"semi-bold\"},{\"path\":\"_fonts/mori/PPMori-Bold.woff2\",\"weight\":\"800\",\"style\":\"bold\"}],\"variable\":\"--font-mori\",\"display\":\"swap\"}],\"variableName\":\"PPMori\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/assets/styles/components/custom.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/assets/styles/components/new-product-image-gallery.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/rc-color-picker/assets/index.css");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/react-step-progress-bar/styles.css");
